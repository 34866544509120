<template>
  <div class="container p-4">
    <h4 class="text-center">Форма жалобы</h4>

    <div class="form-group row mt-4">
      <label for="complaintType" class="col-md-2">Тип жалобы</label>
      <div class="col-md-10">
        <select class="form-control form-select" id="complaintType" v-model="complaintType">
          <option v-for="(item, index) in complaintTypes" :value="item.id" :key="index">
            {{item.name}}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row mt-4">
      <label for="complaintTheme" class="col-md-2 col-form-label">Тема</label>
      <div class="col-md-10">
        <input id="complaintTheme" type="text" class="form-control" placeholder="Тема" v-model="complaintTheme">
      </div>
    </div>

    <div class="form-group row mt-4">
      <label for="complaintDescription" class="col-md-2 col-form-label">Описание</label>
      <div class="col-md-10">
        <textarea id="complaintDescription" class="form-control" rows="5" v-model="complaintDescription"></textarea>
      </div>
    </div>

    <div class="form-group row mt-4">
      <label for="complaintFiles" class="col-md-2 col-form-label">Файлы</label>
      <div class="col-md-10">
        <input id="complaintFiles" type="file" class="form-control" @change="handleFileUpload" multiple>
      </div>
    </div>


    <div class="text-center my-4">
      <button class="btn btn-primary" :disabled="sendComplaintBtnDisabled" @click="sendComplaint">
        <span v-if="sendComplaintBtnDisabled" class="spinner-grow spinner-grow-sm" role="status"
              aria-hidden="true"></span>
        {{ sendComplaintBtnDisabled ? 'Отправление' : 'Отправить жалобу' }}
      </button>
    </div>

  </div>
</template>


<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex"

  export default {
    name: "СomplaintForm",
    data() {
      return {
        files: [],
        complaintType: this.$route.query.complaint_type || null,
        complaintTheme: this.$route.query.complaint_theme || null,
        complaintDescription: null,
        complaintTypes: [
          {
            id: 1,
            name: 'Коррупция'
          },
          {
            id: 2,
            name: 'Другое'
          },
        ],
        sendComplaintBtnDisabled: false
      }
    },
    computed: {
      // ...mapState('complaintForm', ['creditTimeNormTemplate_form']),
    },
    methods: {
      ...mapActions('complaintForm', ['POST_COMPLAINT', 'GET_COMPLAINTS']),
      async sendComplaint() {
        this.sendComplaintBtnDisabled = true
        let formData = new FormData()

        formData.append('complaint_type_id', this.complaintType)
        formData.append('title', this.complaintTheme)
        formData.append('description', this.complaintDescription)

        this.files.forEach(file => {
          formData.append('files[]', file)
        })
        // console.log(Object.fromEntries(formData))

        const res = await this.POST_COMPLAINT(formData)
        if (res) {
          this.files = []
          this.complaintType = null
          this.complaintTheme = null
          this.complaintDescription = null
          this.$message({text: 'Успешно отпрвлено'})
        }
        this.sendComplaintBtnDisabled = false
      },
      handleFileUpload(e) {
        this.files = e.target.files
      },
    },
    mounted() {
      // this.GET_COMPLAINTS()
    }
  }

</script>

<style scoped>

</style>

